import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner';
import { maxDevices, personalNetworkDevices } from '../../../../constants/app';
import { DeviceResponse } from '../../../../api/devices/types';
import { WeSecureWifiCredentialsInterface } from '../../../../api/wifi/client';
import styles from '../../WifiConnect.module.scss';
import { PasswordField } from '../PasswordField';
import { MemberLocation } from '../../../../api/locations/client';
import { CorpForm, CorpFormInterface } from './CorpForm';

type CorporateCreationFormProps = {
  className?: any;
  credentials: WeSecureWifiCredentialsInterface;
  locations: MemberLocation[];
  onSuccessMessage: (msg: JSX.Element) => void;
  onErrorMessage: (msg: JSX.Element) => void;
  onCreate: (resp: DeviceResponse) => void;
};

const CorporateCreationForm: React.FC<CorporateCreationFormProps> = ({
  className,
  credentials,
  locations,
  onSuccessMessage,
  onErrorMessage,
  onCreate,
}) => {
  const [deviceType, setDeviceType] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const formRef = useRef<CorpFormInterface>(null);

  const message = (resp: DeviceResponse) => (
    <div
      className={classNames(
        'ray-grid__cell--span-full',
        styles.textFieldWrapper,
      )}
    >
      <div
        className={classNames(
          styles.notification,
          styles.successfulNotification,
        )}
      >
        <FormattedMessage
          id="tabs.corporate.messages.afterCreate.0"
          defaultMessage="Device ''{device}'' was created!"
          values={{ device: resp.name }}
        />
      </div>

      {resp.is_notificated ? (
        <div
          className={classNames(
            styles.notification,
            styles.successfulNotification,
          )}
        >
          <FormattedMessage
            id="tabs.corporate.messages.afterCreate.1"
            defaultMessage="Thank you for registering your device. We will open a support request on your behalf and will reach out to you if this device requires a reservation of an IP address."
          />
        </div>
      ) : (
        <></>
      )}

      <div className={styles.notification}>
        <FormattedMessage
          id="tabs.corporate.messages.afterCreate.2"
          defaultMessage="Please allow one minute before connecting your device to the ''{network}'' network in order for its credentials to be applied across all systems. You will need to enter password as a shared key when connecting your device to the Internet."
          values={{
            network: (
              <b className={styles.deviceName}>
                {credentials.device_network_name}
              </b>
            ),
          }}
        />

        <br />
        <em>
          <FormattedMessage
            id="tabs.corporate.messages.afterCreate.3"
            defaultMessage="You can always find those instructions at the bottom of this page."
          />
        </em>

        <br />
        <span className={styles.passwordField}>
          <FormattedMessage
            id="tabs.corporate.messages.afterCreate.4"
            defaultMessage="Password:"
          />
          <PasswordField shared_key={credentials.shared_key} />
        </span>
      </div>
    </div>
  );

  return (
    <>
      <CorpForm
        ref={formRef}
        className={className}
        locations={locations}
        onCreate={(resp: DeviceResponse) => {
          if (onSuccessMessage) {
            onSuccessMessage(message(resp));
          }

          onCreate(resp);
        }}
        onError={(msg: JSX.Element) => {
          if (onErrorMessage) {
            onErrorMessage(msg);
          }
        }}
        onSetField={(name, value) => {
          if (name === 'type') {
            setDeviceType(value);
          }
        }}
        onValidate={(isValid: boolean) => setIsValid(isValid)}
        onFinished={() => setIsCreating(false)}
      />

      <div className={className}>
        <button
          className={classNames(
            'ray-button ray-button--primary',
            styles.addDeviceBtn,
          )}
          type="submit"
          onClick={() => {
            setIsCreating(true);
            if (formRef.current) {
              formRef.current.submit();
            }
          }}
          disabled={isValid ? undefined : true}
        >
          {isCreating ? (
            <Loader type="Oval" color="#FFF" height={13} width={13} />
          ) : (
            <FormattedMessage
              id="tabs.corporate.form.actions.create"
              defaultMessage="Add device"
            />
          )}
        </button>
      </div>

      {personalNetworkDevices.includes(deviceType) ? (
        <div
          className={classNames(
            'ray-grid__cell--span-full',
            styles.textFieldWrapper,
          )}
        >
          <div className={styles.notification}>
            <FormattedMessage
              id="tabs.corporate.messages.info.personalDeviceType"
              defaultMessage="This device should be connected using the ''{network}'' network. Please use it with provided credentials."
              values={{
                network: (
                  <b className={styles.deviceName}>
                    {credentials.network_name}
                  </b>
                ),
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CorporateCreationForm;
