import React from 'react';
import styles from '../WifiConnect.module.scss';
import { AppConfig } from '../../../constants/config';

export const WarningMessage: React.FC = () => {
  return AppConfig.showWarningMessage ? (
    <div className={styles.notification}>
      If you have a newer Macbook which includes the M1 or M2 chip, you might be
      encountering issues with connecting to wireless networks. Please see this{' '}
      <a
        href="https://help.wework.com/s/article/Apple-Mac-users-with-M1-and-M2-chips-experiencing-Wi-Fi-dropping"
        target="_blank"
      >
        article
      </a>{' '}
      which will help you determine if you have an M1/M2 Macbook, and the steps
      to try to improve your wireless experience.
    </div>
  ) : (
    <></>
  );
};
