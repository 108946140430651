import classNames from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner';
import { DeviceResponse } from '../../../../api/devices/types';
import styles from '../../WifiConnect.module.scss';
import { Modal, ModalInterface } from '../Modal';
import { Form, FormInterface, FormState } from './Form';
import { MemberLocation } from '../../../../api/locations/client';

export interface EditFormInterface {
  show(): void;
  hide(): void;
}

type EditFormProps = {
  title: JSX.Element;
  locations: MemberLocation[];
  currentMAC?: string;
  deviceData?: FormState;
  onOK: () => void;
  onCancel?: () => void;
  onFinished?: () => void;
  onMessage?: (msg: JSX.Element) => void;
};

export const EditForm = forwardRef<EditFormInterface, EditFormProps>(
  (
    {
      title,
      locations,
      currentMAC,
      deviceData,
      onOK,
      onCancel,
      onFinished,
      onMessage,
    },
    ref,
  ) => {
    const formRef = useRef<FormInterface>(null);
    const modalRef = useRef<ModalInterface>(null);

    const [mac, setMAC] = useState<string>();
    const [device, setDevice] = useState<FormState>();
    const [errorMessage, setErrorMessage] = useState<JSX.Element>();
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      show() {
        if (modalRef.current) {
          modalRef.current.show();
        }
      },
      hide() {
        if (modalRef.current) {
          modalRef.current.hide();
        }
      },
    }));

    useEffect(() => {
      setMAC(currentMAC);
      setDevice(deviceData);

      return () => {
        setMAC('');
        setDevice(undefined);
        setErrorMessage(undefined);
      };
    }, [currentMAC, deviceData]);

    const onSubmit = () => {
      setIsEditing(true);

      if (modalRef.current) {
        modalRef.current.toggleOK();
        modalRef.current.toggleCancel();
      }

      if (formRef.current) {
        formRef.current.submit();
      }
    };

    return (
      <Modal
        ref={modalRef}
        title={title}
        onOK={onSubmit}
        onCancel={() => {
          if (onCancel) {
            onCancel();
          }
          if (formRef.current) {
            formRef.current.cleanup();
          }
        }}
        okText={
          isEditing ? (
            <Loader type="Oval" color="#FFF" height={13} width={13} />
          ) : (
            <FormattedMessage id="modal.actions.ok" defaultMessage="OK" />
          )
        }
        isSelfClosable={false}
      >
        {errorMessage}

        <Form
          showFullForm={true}
          locations={locations}
          ref={formRef}
          currentMAC={mac}
          deviceData={device}
          onError={setErrorMessage}
          onLoad={() => (
            <div className={styles.formLoader}>
              <Loader type="Oval" color="#00F" height={30} width={30} />
            </div>
          )}
          onValidate={(isValid: boolean) => {
            if (modalRef.current) {
              if (isValid) {
                modalRef.current.enableOK();
              } else {
                modalRef.current.disableOK();
              }
            }
          }}
          onEdit={(resp: DeviceResponse) => {
            if (onMessage) {
              onMessage(
                <>
                  <div
                    className={classNames(
                      'ray-grid__cell--span-full',
                      styles.textFieldWrapper,
                    )}
                  >
                    <div
                      className={classNames(
                        styles.notification,
                        styles.successfulNotification,
                      )}
                    >
                      <FormattedMessage
                        id="tabs.device.messages.afterEdit.0"
                        defaultMessage="Device ''{device}'' was edited!"
                        values={{ device: resp.name }}
                      />
                    </div>
                  </div>

                  {resp.is_notificated ? (
                    <div
                      className={classNames(
                        'ray-grid__cell--span-full',
                        styles.textFieldWrapper,
                      )}
                    >
                      <div
                        className={classNames(
                          styles.notification,
                          styles.successfulNotification,
                        )}
                      >
                        <FormattedMessage
                          id="tabs.device.messages.afterEdit.1"
                          defaultMessage="Thank you for registering your device. We will open a support request on your behalf and will reach out to you if this device requires a reservation of an IP address."
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>,
              );
            }
          }}
          onFinished={() => {
            setIsEditing(false);

            if (modalRef.current) {
              modalRef.current.enableOK();
              modalRef.current.enableCancel();
            }
          }}
          onSuccess={() => {
            if (onFinished) {
              onFinished();
            }

            if (modalRef.current) {
              modalRef.current.hide();
              modalRef.current.enableOK();
              modalRef.current.enableCancel();
            }

            onOK();
          }}
        />
      </Modal>
    );
  },
);
